(function ($) {
    $.fn.searchExpertiseDropdown = function () {
        function init() {
            var $this = $(this);

            return $this
                .select2({
                    multiple: false,
                    minimumSelectionSize: 0,
                    maximumSelectionSize: 1,
                    allowClear: false,
                    minimumInputLength: 0,
                    selectOnClose: false,
                    dropdownCssClass: 'search-expertise-dropdown',
                    templateResult: function (state) {
                        if (state.loading) {
                            return null;
                        }

                        var html = '<div class="expertise-item">';

                        if (state._type === 'item' || state._type === 'subitem') {
                            html += '<div class="name">' + state._name + '</div>';

                            if (state._matches && state._matches.length > 0) {
                                html += '<div class="matches">';
                                var matches = [];
                                $.each(state._matches, function (index, match) {
                                    matches.push('<span class="match">' + match + '</span>');
                                });

                                html += matches.join(', ') + '</div>';
                            }
                        } else if (state._type === 'doctor') {
                            html += '<div class="name"><img src="' + state._image + '" height="50" style="border-radius: 25px;"/> ' + state._name + '</div>';
                        }

                        if (state._type === 'suggest') {
                            html += '<div class="suggest" data-suggestion="' + state._name + '">Did you mean <span class="suggestion">' + state._name + '</span>?</div>';
                        }

                        html += '</div>';

                        return $(html);
                    },
                    templateSelection: function (state) {
                        if (state._type === 'item' || state._type === 'subitem') {
                            return state._name;
                        }

                        return state.text;
                    },
                    ajax: {
                        dataType: 'json',
                        data: function (params) {
                            return {
                                q: params.term
                            };
                        },
                        processResults: function (data) {
                            var results = data.items.map(function (item) {
                                console.log(item);
                                var newItem = {
                                    id: item.id,
                                    _name: item.name,
                                    _image: typeof item.image !== 'undefined' ? item.image : null,
                                    _matches: item.matches,
                                    _type: typeof item.id === 'number' ? 'item': 'doctor',
                                };

                                if (item.children.length > 0) {
                                    newItem.children = [];
                                    $.each(item.children, function (index, child) {
                                        newItem.children.push({
                                            id: item.id + '_' + child.id,
                                            _name: child.name,
                                            _type: 'subitem'
                                        });
                                    });
                                }

                                return newItem;
                            });

                            if (data.suggest) {
                                results.unshift({
                                    id: null,
                                    _name: data.suggest,
                                    _type: 'suggest'
                                })
                            }

                            return {
                                results: results
                            };
                        }
                    }
                })
                .on('select2:select', function (e) {
                    var item = e.params.data;
                    var id = null;
                    var $subspec = $($this.data('target-subspec'));

                    if (item._type === 'subitem') {
                        var ids = item.id.split('_');
                        id = ids.pop();
                    } else if (item._type === 'doctor') {
                        window.location.href = '/specialist/' + item.id;
                    }

                    $subspec.val(id);
                })
            ;
        }

        return $(this).each(function () {
            var $el = init.call($(this));
            var $select = $el.data('select2');

            $('body').on('click', '.expertise-item .suggest', function () {
                var value = $(this).data('suggestion');
                var $search = $select.dropdown.$search;
                $search.val(value).trigger('input');
            });
        });
    };
})(jQuery);
