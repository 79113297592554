require('../../plugins/owlcarousel2-2.2.0/owlcarousel/owl.carousel.min');
require('../../plugins/owlcarousel2-2.2.0/owlcarousel/owl.carousel.min.css');
require('../../plugins/owlcarousel2-2.2.0/owlcarousel/owl.theme.default.css');

require('../../../../../../../web/bundles/appadmin/plugins/select2/select2.min');
require('../../../../../../../web/bundles/appadmin/plugins/select2/select2.min.css');

require('../css/dashboard.css');

import App from './app';


$(document).on("focus" ,"#filterByTag, #locationSearch" , function(){
    let  selected = $(this).val();
    if ( selected.length == 0 ) {
        $(this).addClass('blinking-cursor');
    } else {
        $(this).removeClass('blinking-cursor');
    }
});

$(document).on("keyup" ,"#filterByTag, #locationSearch" , function(){
    let  selected = $(this).val();

    if ( selected.length == 0 ) {
        $(this).addClass('blinking-cursor');
    } else {
        $(this).removeClass('blinking-cursor');
    }
});

$(window).scroll(function() {

    let  toTop = $(document).scrollTop();
    let  section2 = $("#topTop_2").val();
    let  section3= $("#topTop_3").val();
    let  section4= $("#topTop_4").val();

    if ( 0 <= toTop && toTop < section2 ) {
        $("#sectionNo").val(1);
    } else if ( section2 <= toTop && toTop < section3 ) {
        $("#sectionNo").val(2);

    } else if ( section3 <= toTop && toTop < section4 ) {
        $("#sectionNo").val(3);
    } else {
        $("#sectionNo").val(4);
    }

    if($(window).scrollTop() + $(window).height() == $(document).height()) {
        $("#btnScrollDown").addClass('hidden');
        $("#btnScrollTop").removeClass("hidden");
    } else {
        $("#btnScrollDown").removeClass('hidden');
        $("#btnScrollTop").addClass("hidden");
    }
});


$(document).on("click", ".testimonialsSlider", function() {
    let  selected = $(this).attr("data-value");
    $("#subSectionNo").val(selected);
});


$(document).on("click", ".homePageTab", function(){
    $(".videoContainer").html('<iframe width="100%" height="415" src="https://www.youtube.com/embed/sSZJGRbX8Rc?rel=0&controls=0&showinfo=0" frameborder="0" allowfullscreen></iframe>');
});

$(window).resize(function(){
    App.calculatePositionToTop(2, 4);
});

$(document).on("click", ".btnShowDoctorModal", function(){

    let  name = $(this).data('name');
    let  description = $(this).data('description');
    let  speciality = $(this).data('speciality');
    let  image = $(this).data('image');
    let  type = $(this).data('type');

    $("#viewDoctorDescription").html(description);
    $("#viewDoctorImage").attr('src', image).attr('alt', name);

    if ( type == "1" ) {

        $("#viewDoctorName").html(name+' - '+speciality);
    } else {
        $("#viewDoctorName").html(name);
    }

    $('#testimonialModal').modal('show');

});

$(document).ready(function(){

    $(".videoContainer").html('<iframe width="100%" height="415" src="https://www.youtube.com/embed/sSZJGRbX8Rc?rel=0&controls=0&showinfo=0" frameborder="0" allowfullscreen></iframe>');

    $('#locationIcon').tooltip({container: 'body'});


    $("#sectionNo").val(1);
    App.calculatePositionToTop(2, 4);

    let  toTop = $(document).scrollTop();
    let  section2 = $("#topTop_2").val();
    let  section3= $("#topTop_3").val();
    let  section4= $("#topTop_4").val();

    if ( 0 < toTop < section2 ) {
        $("#sectionNo").val(1);
    } else if ( section2 < toTop < section3 ) {
        $("#sectionNo").val(2);
    } else if ( section3 < toTop < section4 ) {
        $("#sectionNo").val(3);
    } else {
        $("#sectionNo").val(4);
    }


    let  owl = $("#top-carousel");

    owl.on('initialized.owl.carousel', function(event) {
        $("#sliderLoading").remove();
    });

    owl.owlCarousel({
        callbacks: true,
        loop:true,
        autoplay:true,
        autoplayTimeout:3000,
        margin:0,
        nav:false,
        dots:false,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    });

    setTimeout(function(){
        loadSearchForm();
        loadTabs();
    }, 700);


});

let loadSearchForm = () => {
    $.ajax({
        url: Routing.generate('app_web_load_search_form'),
        method: 'POST'
    }).then((data) => {
        $(".js-search-form").addClass('home-search-box').html(data.data);
        require('./components/Search');
    })
};

let loadTabs = () => {
    $.ajax({
        url: Routing.generate('app_web_load_tabs'),
        method: 'POST'
    }).then((data) => {
        $(".js-tabs-content").html(data.data);

        const $reviewPatients = $("#review-patient");
        const $reviewDoctors = $("#review-doctors");
        testimonialsSlider($reviewPatients);
        testimonialsSlider($reviewDoctors);

        const $specialitiesExplained = $('#speciality-slider');
        specialitySlider($specialitiesExplained);
    })
};

let specialitySlider = ($specialities) => {
    $specialities.owlCarousel({
        loop:true,
        margin:50,
        dots:false,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:4
            }
        },
        navText: [
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>"
        ]
    });
};

let testimonialsSlider = ($reviewer) => {
    $reviewer.owlCarousel({
        loop:true,
        margin:10,
        dots:false,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1300:{
                items:2
            }
        },
        navText: [
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>"
        ]
    });
};

$(document).on("click", '#find-specialist-home-btn', function () {
    goToByScroll("#searchForm");
});

/**
 * Function to scroll to anchor
 */
function goToByScroll(id){

    $('html,body').animate({scrollTop: $(id).offset().top - 100 }, 300);
}