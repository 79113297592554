require('../../../js/jquery.slimscroll.min');
require('../../../js/jquery.search-expertise-dropdown');

$(document).on("focus", "#locationSearch", function(){
    $("#locationSearch").attr("placeholder", "Insert location...");
});

$("#locationIcon").on("click", function(){
    getGeoLocation();
});


function toggleExpertiseSection(id) {
    let  currentSection = $('#expertise_' + id);
    let  isOpen = currentSection.find('.expertiseCaret').hasClass('sectionIsOpen') ? true : false;

    let  caret = currentSection.find('.expertiseCaret');
    let  subspecs = currentSection.find('.expertiseSubspeciality');

    if (isOpen) {
        caret.removeClass('sectionIsOpen').addClass('sectionIsClosed');
        subspecs.addClass('hidden');
    } else {
        caret.removeClass('sectionIsClosed').addClass('sectionIsOpen');
        subspecs.removeClass('hidden');
    }
}

$(document).ready(function() {
    $('#expertiseScroll').slimscroll({
        height: 'auto'
    });

    $('#insurersScroll').slimscroll({
        height: 'auto'
    });

    $('#filterByTag').on('keyup click', function () {
        let  search = jQuery.trim($(this).val());
        let  boxes = $('.expertiseBox');

        if (search.length === 0) {
            boxes.removeClass('hidden');
            boxes.find('.expertiseCaret').removeClass('sectionIsOpen').addClass('sectionIsClosed');
            boxes.find('.expertiseSubspeciality').addClass('hidden');

            return;
        }

        boxes.addClass('hidden');
        boxes.find('.expertiseCaret').removeClass('sectionIsOpen').addClass('sectionIsClosed');
        boxes.find('.expertiseSubspeciality').addClass('hidden');

        let  elements = $("div.expertiseBox:regex(data-tags, .*" + search + ".*)");
        elements.each(function () {
            let  box = $(this);

            box.find('.expertiseCaret').removeClass('sectionIsOpen').addClass('sectionIsClosed');
            box.find('.expertiseSubspeciality').addClass('hidden');
            box.removeClass('hidden');
        });

        let  unhiddenExpertiseBoxes = $('.expertiseBox:not(.hidden)');

        let  boxesUnhiddenCount = unhiddenExpertiseBoxes.length;

        if (boxesUnhiddenCount === 1) {
            let  expertiseId = $(unhiddenExpertiseBoxes[0]).data('id');
            let  isOpen = $('#expertise_' + expertiseId).find('.expertiseCaret').hasClass('sectionIsOpen') ? true : false;

            if (!isOpen) {
                toggleExpertiseSection(expertiseId);
            }
        }
    });

    $('#filterByTag').on('keyup', function () {
        let  totalBoxesCount = $('.expertiseBox').length;
        let  boxesHiddenCount = $('.expertiseBox.hidden').length;

        $('#searchFormSubmit').prop('disabled', true);

        if (totalBoxesCount === boxesHiddenCount) {
            $('#expertiseNoResults').removeClass('hidden');
        } else {
            $('#expertiseNoResults').addClass('hidden');
        }

        if ( $(this).val().length == 0 ) {
            $('#searchFormSubmit').prop('disabled', false);
        }

    });

    $('#filterByTag').on('keydown', function () {

        if (!$('#expertiseSelect').hasClass('open')) {
            $('#expertiseSelect').addClass('open');
        }
    });

    $('#expertiseSelect .dropdown-menu').on('click', function(event){
        let  events = $._data(document, 'events') || {};
        events = events.click || [];
        for(let  i = 0; i < events.length; i++) {
            if(events[i].selector) {

                if($(event.target).is(events[i].selector)) {
                    events[i].handler.call(event.target, event);
                }

                $(event.target).parents(events[i].selector).each(function(){
                    events[i].handler.call(this, event);
                });
            }
        }
        event.stopPropagation(); //Always stop propagation
    });

    let locationSearch = document.getElementById('locationSearch');
    if ( locationSearch != null ) {
        let  autocomplete = new google.maps.places.Autocomplete(document.getElementById('locationSearch'), {types: ['geocode']});
        autocomplete.addListener('place_changed', function () {
            $('#searchLat').val(this.getPlace().geometry.location.lat());
            $('#searchLng').val(this.getPlace().geometry.location.lng());
        });
    }


    $('#locationSearch').change(function () {
        $('#searchLat').val('');
        $('#searchLng').val('');
    });

    $('.search-expertise-wrapper select').searchExpertiseDropdown();
});

jQuery.expr[':'].regex = function(elem, index, match) {
    let  matchParams = match[3].split(','),
        validLabels = /^(data|css):/,
        attr = {
            method: matchParams[0].match(validLabels) ?
                matchParams[0].split(':')[0] : 'attr',
            property: matchParams.shift().replace(validLabels,'')
        },
        regexFlags = 'ig',
        regex = new RegExp(matchParams.join('').replace(/^\s+|\s+$/g,''), regexFlags);
    return regex.test(jQuery(elem)[attr.method](attr.property));
};

function getGeoLocation() {
    if (navigator.geolocation) {

        $("#locationIcon, #locationIcon .faLocationIcon").addClass("pulseAnimation");

        navigator.geolocation.getCurrentPosition(function(position) {
            let  lat = position.coords.latitude;
            let  lng = position.coords.longitude;

            $('#searchLat').val(lat);
            $('#searchLng').val(lng);

            let  geocoder = new google.maps.Geocoder;

            geocoder.geocode({'location': { lat: lat, lng: lng}}, function(results, status) {
                if (status === 'OK') {
                    if (results[0]) {
                        $('#locationSearch').val(results[0].formatted_address);
                    }
                }
                $("#locationIcon, #locationIcon .faLocationIcon").removeClass("pulseAnimation");

            });
        }, function(positionError) {
            $("#locationIcon, #locationIcon .faLocationIcon").removeClass("pulseAnimation");
            $("#locationSearch").attr("placeholder", "We can't locate you");
        });
    }

}

$(document).on('click', "#dropExpertise, #dropdownMenu1, #locationSearch", function(){
    goToByScrollSearch("#searchForm");
});

function goToByScrollSearch(id){

    $('html,body').animate({scrollTop: $(id).offset().top - 60 }, 300);
}

$(document).on('click', '.js-select-insurer', function () {
    let name = $(this).data('name');
    let id = $(this).data('id');

    selectInsurer(name, id);
});

function selectInsurer(text, id)
{
    $('#insurerLabel').html(text);
    $('#insurerId').val(id);
}
